var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"8"}},[_c('v-card',[_c('v-card-title',{staticClass:"d-flex justify-space-between"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("crudActions.indexModel", { model: _vm.$t("models.bettingPools.multiple").toLowerCase(), }))+" ")]),_c('v-btn',{attrs:{"small":"","color":"primary","to":{ name: _vm.$routes.bettingPool.createRoute.name }}},[_vm._v(" "+_vm._s(_vm.$t("crudActions.createModel", { model: _vm.$t("models.bettingPools.single") }))+" ")])],1),_c('v-card-text',[_c('data-table',{attrs:{"id":"pool-index-table","loading":_vm.loading,"headers":_vm.tableColumns,"items":_vm.bettingPools},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.username)+" ")]}},{key:"item.balance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$formatter.asCurrency(_vm.balancesMap[item.accountableEntityId] || 0))+" ")]}},{key:"item.cutOffBalance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$formatter.asCurrency(_vm.groupedSales[item.id] ? _vm.groupedSales[item.id].cutoffBalance : 0))+" ")]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-layout',{attrs:{"row":"","wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","md2":""}},[_c('v-switch',{staticClass:"switch-center",attrs:{"inset":""},on:{"change":function($event){return _vm.toggleActive(item)}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})],1)],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ma-md-4",on:{"click":function($event){return _vm.editBettingPool(item)}}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":"pencil-alt"}}),_vm._v(" Edit ")],1),(_vm.superuser)?_c('v-btn',{staticClass:"ma-md-4",attrs:{"color":"info"},on:{"click":function($event){return _vm.confirmPurseClear(item)}}},[_vm._v(" Clear purse ")]):_vm._e(),(_vm.superuser)?_c('v-btn',{attrs:{"color":"info"},on:{"click":function($event){return _vm.confirmJackpotClear(item)}}},[_vm._v(" Clear jackpot ")]):_vm._e()]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }