<template>
  <v-row
    align="center"
    justify="center">
    <v-col
      cols="12"
      sm="8">
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <span> {{ $t("crudActions.indexModel", {
            model: $t("models.bettingPools.multiple").toLowerCase(),
          }) }} </span>
          <v-btn
            small
            color="primary"
            :to="{ name: $routes.bettingPool.createRoute.name }">
            {{ $t("crudActions.createModel", { model: $t("models.bettingPools.single") }) }}
          </v-btn>
        </v-card-title>
        <v-card-text>
          <data-table
            id="pool-index-table"
            :loading="loading"
            :headers="tableColumns"
            :items="bettingPools">
            <template #item.user="{ item }">
              {{ item.user.username }}
            </template>
            <template #item.balance="{ item }">
              {{ $formatter.asCurrency(balancesMap[item.accountableEntityId] || 0) }}
            </template>
            <template #item.cutOffBalance="{ item }">
              {{ $formatter.asCurrency(groupedSales[item.id] ? groupedSales[item.id].cutoffBalance : 0) }}
            </template>
            <template #item.active="{ item }">
              <v-layout
                row
                wrap
                justify-center>
                <v-flex
                  xs12
                  md2>
                  <v-switch
                    v-model="item.active"
                    inset
                    class="switch-center"
                    @change="toggleActive(item)" />
                </v-flex>
              </v-layout>
            </template>
            <template #item.action="{ item }">
              <v-btn
                class="ma-md-4"
                @click="editBettingPool(item)">
                <font-awesome-icon
                  icon="pencil-alt"
                  class="mr-1" />
                Edit
              </v-btn>
              <v-btn
                v-if="superuser"
                color="info"
                class="ma-md-4"
                @click="confirmPurseClear(item)">
                Clear purse
              </v-btn>
              <v-btn
                v-if="superuser"
                color="info"
                @click="confirmJackpotClear(item)">
                Clear jackpot
              </v-btn>
            </template>
          </data-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { fetchBettingPools, updateBettingPoolActiveStatus } from "@/api/bettingPool.js";
import ContentLoader from "../../mixins/ContentLoader.js";
import { i18n } from "@/i18n";
import { confirmJackpotClear } from "@/utils/jackpot.js";
import { confirmPurseClear } from "@/utils/wallet.js";
import DataTable from "@/components/DataTable.vue";
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "BettingPoolIndex",
  components: { DataTable },
  mixins: [ContentLoader],
  data() {
    return {
      sales: [],
      bettingPools: [],
      showSnackbar: false,
      snackbarText: "",
      snackbarColor: "Red",
      balancesMap: {},
    };
  },
  computed: {
    ...mapGetters(
      {
        superuser: "superuser"
      },
    ),
    groupedSales() {
      return _.keyBy(this.sales, "bettingPoolId");
    },
    tableColumns() {
      return [
        {
          value: "number",
          text: "#",
          align: "center",
        },
        {
          value: "name",
          text: i18n.t("attributes.bettingPools.name"),
          align: "center",
        },
        {
          value: "reference",
          text: i18n.t("attributes.bettingPools.reference"),
          align: "center",
        },
        {
          value: "user",
          text: i18n.t("attributes.bettingPools.user"),
          align: "center",
        },
        {
          value: "balance",
          text: i18n.t("attributes.bettingPools.balance"),
          align: "center",
        },
        {
          value: "cutOffBalance",
          text: i18n.t("attributes.bettingPools.cutOffBalance"),
          align: "center",
        },
        {
          value: "active",
          text: i18n.t("attributes.bettingPools.active"),
          sortable: false,
          align: "center",
        },
        {
          value: "action",
          text: i18n.t("actions.text"),
          sortable: false,
          align: "center",
        },
      ];
    },
  },
  async mounted() {
    this.$startLoading();

    try {
      const {
        data: { bettingPools, balances, sales },
      } = await fetchBettingPools();

      this.bettingPools = bettingPools;
      this.balancesMap = balances;
      this.sales = sales;

      await this.$nextTick();
    } finally {
      this.$finishedLoading();
    }
  },
  methods: {
    classForBalance(number) {
      if (parseFloat(number) < 0) {
        return "danger";
      }
      return "success";

    },
    editBettingPool({ id }) {
      this.$router.push({
        name: this.$routes.bettingPool.editRoute.name,
        params: { id },
      });
    },
    toggleActive(bettingPool) {
      updateBettingPoolActiveStatus(bettingPool.id, bettingPool.active).catch((error) => {
        console.error(error);
        bettingPool.active = !bettingPool.active;
      });
    },
    confirmPurseClear,
    confirmJackpotClear,
  },
};
</script>

<style
  lang="scss"
  scoped>
.balance {
  padding: 10px;
  color: white;

  .success {
    background-color: #4CAF50;
  }

  .danger {
    background-color: #bd2b2b;
  }
}

</style>
