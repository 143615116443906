import httpClient from "./httpClient";
import BettingPoolUpdateCategory from "@/imported/BettingPoolUpdateCategory.json";
import BettingPoolParamsCategory from "@/imported/BettingPoolParamsCategory.json";

const resource = "betting_pools";

export function createBettingPool(bettingPool) {
  return httpClient.post(`/${resource}`, { bettingPool });
}

export function fetchBettingPools() {
  return httpClient.get(`/${resource}`);
}

export function fetchBettingPool(id) {
  return httpClient.get(`/${resource}/${id}`);
}

export function updateBettingPool(id, form) {
  const submitForm = {
    bettingPool: {
      ...form,
      id,
    },
    category: BettingPoolUpdateCategory.general,
  };

  return httpClient.put(`/${resource}/${id}`, submitForm);
}

export function updateBettingPoolActiveStatus(id, active) {
  const form = {
    category: BettingPoolUpdateCategory.activeStatus,
    bettingPool: {
      id,
      active,
    },
  };

  return httpClient.put(`/${resource}/${id}`, form);
}

export function updateSettings(id, form) {
  const submitForm = {
    bettingPool: {
      id: id,
      ...form,
    },
    category: BettingPoolUpdateCategory.commonAmounts,
  };

  return httpClient.put(`/${resource}/${id}`, submitForm);
}

export function getCreateOrUpdateParams(bettingPoolId) {
  return httpClient.get(`/${resource}/params`, {
    params: {
      bettingPoolId,
      category: BettingPoolParamsCategory.createOrUpdate,
    },
  });
}

export function getSettingsParams(bettingPoolId) {
  return httpClient.get(`/${resource}/params`, {
    params: {
      bettingPoolId,
      category: BettingPoolParamsCategory.settings,
    },
  });
}
